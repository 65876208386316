import * as React from "react";
import { use100vh } from "react-div-100vh";
import styled from "styled-components";

// Previews
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";

// SEO
// import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 250px 0 0 0;
  min-height: calc(${(props) => props.height}px - 200px);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & .page-container {
    max-width: 440px;
    margin: 0 auto;
  }

  & h1,
  & p {
    text-align: center;

    & a {
    }
  }

  @media (max-width: 960px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    & h1,
    & p {
      font-size: 18px;
      line-height: 25px;
    }
  }
`;

const NotFoundPage = () => {
  const height = use100vh();

  return (
    <>
      {/* <PageSeo seoTitle={`Page Not Found`} seoText={null} seoImage={null} /> */}

      <Page height={height}>
        <div className="page-container">
          <div className="text-container">
            <h1>404 Page Not Found</h1>
            <p>The page you requested does not exist</p>
          </div>
        </div>
      </Page>
    </>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
